<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="7"
                lg="10"
                md="10"
                sm="6"
            >
                <h3>Cotações</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                lg="10"
                md="12"
                sm="12"
            >
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        :label="$t('pesquisaRapida')"
                        append-outer-icon="mdi mdi-magnify"
                        outlined
                        single-line
                        @click="options.page=1;"
                        @click:append-outer="getRegisters"
                    ></v-text-field>
                </v-form>
            </v-col>            
            <v-col 
                cols="12"
                lg="2"
                md="12"
                sm="12"
            >
                <v-switch 
                    v-model="mostrarRevisoes"
                    label="Mostrar Revisões"
                    color="var(--color__main)"
                    :value=0
                    @change="getRegisters"
                >
                </v-switch>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    :no-data-text="$t('nenhumRegistroEncontrado')"
                    :footer-props="{
                        'items-per-page-text':$t('registrosPorPagina'),
                        'items-per-page-all-text':$t('todos'),
                        pageText: '{0}-{1} de {2}'
                    }"
            >
                    <template v-slot:item.numeroCotacao="{ item }">
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <a v-bind:href="item.customerUrl" v-on="on" target="_blank">
                                    {{ item.numeroCotacao }}
                                </a>
                            </template>
                            <span>Link Cliente</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.statusDescricao="{ item }">
                        <v-chip
                            style="font-size: 12px !important;"
                            small
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.statusDescricao }}
                        </v-chip>
                    </template>

                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'cotacaoForm'"
                            :showButtons="{
                                editarRevisao: item.revisao == 0 && (item.status == 1 || item.status == 4),
                                cancelarCotacao: item.revisao == 0 && item.status == 1,
                                downloadCotacao: true,
                                criarPedido: item.status == 1 && userLoggedGetters.perfil.criacaoPedidos == 1,
                                clonar: item.revisao == 0 && item.status != 6,
                                copiar: item.revisao == 0 && item.status != 6,
                            }"
                            @confirmCancelCotacao="confirmCancelCotacao"
                            @confirmDownloadCotacao="confirmDownloadCotacao"
                            @confirmCriarPedido="validarQtdeMinima"
                            @confirmClone="confirmClone"
                            @copiar="copiar"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

		<v-dialog
			v-model="showDialogCriarPedido"
			transition="dialog-top-transition"
			persistent
			width="1000"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Dados para o pedido
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <ContentHeader :description="'Dados do cliente'" />
                        </v-col>
                    </v-row>
					<v-row>
						<v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
						>
							<label>Número Pedido</label>
                            <v-text-field
                                v-model="numeroPedidoCliente"
                                single-line
                                outlined
                            >
                            </v-text-field>
						</v-col>

						<v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
						>
							<label>{{ $t('email') }}</label>
                            <v-text-field
                                v-model="emailCliente"
                                single-line
                                outlined
                            >
                            </v-text-field>
						</v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <ContentHeader :description="'Dados do pedido'" />
                        </v-col>
                    </v-row>

                    <v-row>                        
						<v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
						>
							<label>Transportadora</label>
                            <v-combobox
                                v-model="transportadoraSelected"
                                :items="listTransportadora"
                                item-text="idAndNameProcessed"
                                item-value="id"
                                :loading="loadingTransportadora"
                                clearable
                                outlined
                            ></v-combobox>
						</v-col>
                    </v-row>

                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <canvas id="canvas" hidden></canvas>
                            <label>Ordem de Compra</label>
                            <v-file-input
                                v-model="ordemCompraListFiles" 
                                accept=".*"
                                small-chips
                                multiple
                                outlined
                                placeholder="Clique aqui para adicionar"
                                prepend-inner-icon="mdi-paperclip"
                                prepend-icon=""
                                @change="addFilesToList(ordemCompraListFiles, ordemCompraFiles)"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <label>Evidência do Preço</label>
                            <v-file-input
                                v-model="evidenciaPrecoListFiles" 
                                accept=".*"
                                small-chips
                                multiple
                                outlined
                                placeholder="Clique aqui para adicionar"
                                prepend-inner-icon="mdi-paperclip"
                                prepend-icon=""
                                @change="addFilesToList(evidenciaPrecoListFiles, evidenciaPrecoFiles)"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <label>Outros</label>
                            <v-file-input
                                v-model="outrosListFiles" 
                                accept=".*"
                                small-chips
                                multiple
                                outlined
                                placeholder="Clique aqui para adicionar"
                                prepend-inner-icon="mdi-paperclip"
                                prepend-icon=""
                                @change="addFilesToList(outrosListFiles, outrosFiles)"
                            ></v-file-input>
                        </v-col>
                    </v-row>

                    <v-row>                        
						<v-col 
                            cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>Mensagem</label>
                            <v-textarea
                                v-model="mensagemNota"
                                single-line
                                rows="4"
                                row-height="30"
                                outlined
                            >
                            </v-textarea>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel"
							outlined
							@click="showDialogCriarPedido = false"
						>
							Cancelar
						</v-btn>

						<v-btn
							class="mx-2"
							outlined
							@click="confirmCriarPedido()"
						>
							Confirmar
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { TIPO_DOCUMENTO, TIPO_ANEXO } from "@/utilities/Enums";
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import { validEmail } from "@/utilities/Rules";

    export default ({

        components: {
            ActionList,
            ActionDialog,
            ContentHeader
        },

        mixins: [Helpers],

        data: () => ({

            loading: false,
            statusId: 0,

            filter: {
                fastSearch: ''
            },

            mostrarRevisoes: 0,

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [],

            options: { rowsPerPage: 10, page: 1 },

            listCotacao: [],

            elegivelParaCampanha: false,
            numeroPedidoCliente: '',
            emailCliente: '',
            naturezaSelected: null,
            loadingNatureza: false,
            listNaturezas: [],

            loadingTransportadora: false,
            transportadoraSelected: null,
            listTransportadora: [],

            id: 0,
            mensagemNota: "",
            showDialogCriarPedido: false,

            ordemCompraListFiles: [],
            ordemCompraFiles: [],

            evidenciaPrecoListFiles: [],
            evidenciaPrecoFiles: [],

            outrosListFiles: [],
            outrosFiles: [],

            validations: {
                email: validEmail
            },
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            filteredSearch: function() {
                return this.listCotacao.filter((cotacaoFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var dataCadastroFormatted = cotacaoFilter.dataCadastroFormatted.toLowerCase().match(filter)
                    var numeroCotacao = cotacaoFilter.numeroCotacao.toLowerCase().match(filter)
                    var nomeUsuarioCadastro = cotacaoFilter.nomeUsuarioCadastro.toLowerCase().match(filter)
                    var marcaDescricao = cotacaoFilter.marcaDescricao.toLowerCase().match(filter)
                    var tipoClienteDescricao = cotacaoFilter.tipoClienteDescricao.toLowerCase().match(filter)
                    var nomeClienteDistribuidor = cotacaoFilter.nomeClienteDistribuidor.toLowerCase().match(filter)
                    var valorTotalBrutoFormatted = cotacaoFilter.valorTotalBrutoFormatted.toLowerCase().match(filter)
                    var statusDescricao = cotacaoFilter.statusDescricao.toLowerCase().match(filter)
                    var id = cotacaoFilter.id.toString().match(filter);

                    if(dataCadastroFormatted != null) { return dataCadastroFormatted; }
                    else if(numeroCotacao != null) { return numeroCotacao; }
                    else if(marcaDescricao != null) { return marcaDescricao; }
                    else if(nomeUsuarioCadastro != null) { return nomeUsuarioCadastro; }
                    else if(tipoClienteDescricao != null) { return tipoClienteDescricao; }
                    else if(nomeClienteDistribuidor != null) { return nomeClienteDistribuidor; }
                    else if(valorTotalBrutoFormatted != null) { return valorTotalBrutoFormatted; }
                    else if(statusDescricao != null) { return statusDescricao; }
                    else { return id; }
                });
            },

            isUsuarioInterno() {
                return this.userLoggedGetters.usuarioInterno == 1;
            },

            temDistribuidorPai() {
                return this.userLoggedGetters.temDistribuidorPai == 1;
            },
        },

        methods: {

            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 1: //ATIVO
                        color = 'var(--color__main)';
                        break;

                    case 2: //CANCELADO
                        color = 'var(--color__red)';
                        break;

                    case 3: //CONVERTIDO EM PEDIDO
                        color = 'var(--color__blue)';
                        break;

                    case 4: //NEGADO
                        color = 'var(--color__red)';
                        break;

                    case 5: //APROVADO
                        color = 'var(--color__main)';
                        break;

                    case 6: //EXPIRADO
                        color = 'var(--color__red)';
                        break;
                
                    default:
                        break;
                }

                return color
            },

            async getRegisters() {

                let mostrarRevisao = 0;

                if (this.mostrarRevisoes != null && this.mostrarRevisoes != undefined) {
                    mostrarRevisao = this.mostrarRevisoes;

                    if (mostrarRevisao == true) {
                        mostrarRevisao = 1;
                    }
                    else {
                        mostrarRevisao = 0;
                    }
                }

                this.listCotacao = await this.$store.dispatch("orcamentoModule/List", mostrarRevisao);

                this.loadingNatureza = true;
                this.listNaturezas = await this.$store.dispatch("naturezaModule/List");
                this.loadingNatureza = false;

                this.loadingTransportadora = true;
                this.listTransportadora = await this.$store.dispatch("transportadoraModule/List");
                this.loadingTransportadora = false;
            },

            confirmCancelCotacao(id) {

                this.statusId = 0;

                this.dialog = {
                    show: true,
                    headerText: this.$t('confirmacao'),
                    bodyText: 'Você irá CANCELAR essa COTAÇÃO do sistema, confirma sua decisão?',
                    methodConfirm: this.cancelCotacao,
                    params: id
                };

            },

            async cancelCotacao(id) {

                this.showLoading();

                let result = await this.$store.dispatch("orcamentoModule/AlterarStatus", { id, statusId: 2 });

                if (result.success) {
                    this.showToast("success", this.$t('sucesso'), "Cotação cancelada com sucesso");
                }
                else {
                    this.showToast("error", "Erro!", "Erro ao cancelar a cotação");
                }

                await this.getRegisters();

                this.hideLoading();
            },

            confirmDownloadCotacao(id) {

                let cotacao = this.listCotacao.filter(cot => cot.id == id)[0];

                if (cotacao != null && cotacao != undefined) {

                    let revisao = cotacao.revisao;

                    this.showLoading();

                    this.$spTechApi.get(`orcamento/download-orcamento/${revisao}/${id}`, {
                            responseType: "blob",
                        })
                        .then((response) => {

                            var blob = new Blob([response.data], {
                                type: "application/octet-stream",
                            });

                            var fileName = `Cotação_${cotacao.numeroCotacao}.pdf`;

                            var url = window.URL.createObjectURL(blob);
                            var a = document.createElement("a");

                            document.body.appendChild(a);
                            a.style = "display: none";
                            a.href = url;
                            a.download = fileName;
                            a.click();
                            window.URL.revokeObjectURL(url);

                            this.hideLoading();
                        })
                        .catch(error => {
                            
                            let errorMessage = "";
                            if (error.response && error.response.status === 400) {
                                errorMessage = `Erro ao efetuar download da cotação: [${error.response.data}]`;
                            }
                            else {
                                errorMessage = `Erro ao efetuar download da cotação: [${error}]`;
                            }
                            
                            this.showToast("error", "Erro!", errorMessage);
                            this.hideLoading();
                        }
                    );
                }
            },

            async validarQtdeMinima(id) {

                this.showLoading();

                const cotacao = this.listCotacao.filter(cot => cot.id == id)[0];
                let cotacaoValida = true;
                let qtdeLida = 0;
                let listProdutoQtde = [];

                const listItens = await this.$store.dispatch("orcamentoModule/GetItensCompraMinima", id);

                if (cotacao != null && cotacao != undefined &&
                    listItens != null && listItens != undefined && listItens.length > 0) {

                    await listItens.forEach(async item => {

                        const produtoAdicionado = listProdutoQtde.filter(prd => prd.idProduto == item.idProduto)[0];

                        if (produtoAdicionado == null || produtoAdicionado == undefined) {
                            listProdutoQtde.push({
                                idProduto: item.idProduto,
                                qtde: parseInt(item.qtde),
                                qtdeEstoque: parseInt(item.qtdeEstoque),
                                compraMinimaQtde: parseInt(item.compraMinimaQtde),
                            })
                        }
                        else {
                            produtoAdicionado.qtde += parseInt(item.qtde);
                        }

                        qtdeLida++;
                    });
                }

                const interval = setInterval(() => {

                    if (qtdeLida == listItens.length) {

                        listProdutoQtde.forEach(itemProdutoQtde => {
                            if (parseInt(itemProdutoQtde.qtde) > parseInt(itemProdutoQtde.qtdeEstoque) && 
                               (parseInt(itemProdutoQtde.qtde) < itemProdutoQtde.compraMinimaQtde)) {
                                cotacaoValida = false;
                                this.showToast("error", "Atenção", `<strong>Não é possível converter a cotação em pedido!</strong><br />O produto possui uma qtde mínima de [${itemProdutoQtde.compraMinimaQtde}un] configurada e na cotação foi pedida [${itemProdutoQtde.qtde}un] e possui em estoque [${itemProdutoQtde.qtdeEstoque}un].`);
                            }
                        })

                        if (cotacaoValida == true) {
                            this.mostrarMensagemNota(id);
                        }

                        clearInterval(interval);

                        this.hideLoading();
                    }

                }, 500);
            },

            mostrarMensagemNota(id) {
                this.id = id;
                const cotacao = this.listCotacao.filter(cot => cot.id == id)[0];

                if (cotacao != null && cotacao != undefined) {
                    const transportadora = this.listTransportadora.filter(trp => trp.id == cotacao.idTransportadora)[0];
                    const natureza = this.listNaturezas.filter(nat => nat.idProcessed == cotacao.idNatureza)[0];
                    
                    this.transportadoraSelected = transportadora;
                    this.naturezaSelected = natureza;
                }

                this.showDialogCriarPedido = true;
            },

            addFilesToList(listFiles, files) {

                if (listFiles) {

                    listFiles.forEach(itemFile => {

						if (itemFile) {

                            let nomeArquivo = itemFile.name;

                            if (itemFile.name && itemFile.name.length > 15) {
                                nomeArquivo = itemFile.name.toString().substring(0, 12) + "..."
                            }

                            let item = {
                                id: 0,
                                arquivo: itemFile,
                                nomeArquivo: nomeArquivo,
                                excluido: false,
                            }

                            files.push(item);
						}	
					});
                }
            },

            async uploadFiles(id, chave, arquivo, tipoDocumento, excluido = false) {

                let anexoRequest = {
                    id: id,
                    chave: chave,
                    arquivo: arquivo ? arquivo : new Blob(),
                    nome: arquivo ? arquivo.name : "",
                    tipo: arquivo ? arquivo.type : "",
                    tamanho: arquivo ? arquivo.size : 0,
                    excluido: excluido ? excluido : false,
                    tipoAnexo: TIPO_ANEXO.PEDIDO.value,
                    tipoDocumento: tipoDocumento,
                }

                if (anexoRequest.nome != null && anexoRequest.nome != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("anexoRequest", JSON.stringify(anexoRequest));
                    attachmentFileRequest.append("anexoArquivo", anexoRequest.arquivo, anexoRequest.nome);

                    const result = await this.$store.dispatch("anexoModule/CreateUpdate", attachmentFileRequest);

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async saveFiles(idPedido) {

                let allResult = [];

                this.ordemCompraFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, idPedido, itemFile.arquivo, TIPO_DOCUMENTO.ORDEM_COMPRA.value, itemFile.excluido);

                    if (result.success == false) {
                        allResult.push({
                            tipoDocumento: TIPO_DOCUMENTO.ORDEM_COMPRA.description,
                            message: result.message
                        })
                    }
                })

                this.evidenciaPrecoFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, idPedido, itemFile.arquivo, TIPO_DOCUMENTO.EVIDENCIA_PRECO.value, itemFile.excluido);

                    if (result.success == false) {
                        allResult.push({
                            tipoDocumento: TIPO_DOCUMENTO.EVIDENCIA_PRECO.description,
                            message: result.message
                        })
                    }
                })

                this.outrosFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, idPedido, itemFile.arquivo, TIPO_DOCUMENTO.OUTROS.value, itemFile.excluido);

                    if (result.success == false) {
                        allResult.push({
                            tipoDocumento: TIPO_DOCUMENTO.OUTROS.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `O [${itemResult.tipoDocumento}] reportou o seguinte erro: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", this.$t('aviso'), message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async checkValidations() {
                if (this.isUsuarioInterno == true && this.emailCliente == null || this.emailCliente == undefined || this.emailCliente.toString().trim() == "") {
                    this.showToast("error", "Erro!", "Campo e-mail é obrigatório!"); 
                    return false;
                }
                else {
                    return true;
                }
            },       

            async confirmCriarPedido() {

                if (await this.checkValidations() == true) {
                    this.dialog = {
                        show: true,
                        headerText: this.$t('confirmacao'),
                        bodyText: 'Você está prestes a CRIAR UM NOVO PEDIDO no sistema, confirma sua decisão?',
                        methodConfirm: this.criarPedido,
                        params: 0
                    };
                }
            },

            async criarPedido() {

                this.showLoading();
                
                let revisao = 0;
                let idNatureza = "0";

                if (this.naturezaSelected != null && this.naturezaSelected != undefined) {
                    idNatureza = this.naturezaSelected.idProcessed;
                }

                let idTransportadora = "0";

                if (this.transportadoraSelected != null && this.transportadoraSelected != undefined) {
                    idTransportadora = this.transportadoraSelected.id;
                }

                if (this.mostrarRevisoes != null && this.mostrarRevisoes != undefined) {
                    revisao = this.mostrarRevisoes;

                    if (revisao == true) {
                        revisao = 1;
                    }
                    else {
                        revisao = 0;
                    }
                }

                const requestPedido = {
                    id: this.id,
                    mensagemNota: this.mensagemNota,
                    idNatureza,
                    idTransportadora,
                    revisao,
                    numeroPedidoCliente: this.numeroPedidoCliente,
                    emailCliente: this.emailCliente
                }

                let result = await this.$store.dispatch("orcamentoModule/CriarPedido", requestPedido);

                if (result.success) {

                    if (await this.saveFiles(parseInt(result.idPedido))) {
                        this.showToast("success", this.$t('sucesso'), `Pedido #${result.idPedido} criado com sucesso!`);
                        this.showDialogCriarPedido = false;
                    }
                }
                else {
                    this.showToast("error", "Erro!", result.message);
                }

                await this.getRegisters();

                this.hideLoading();
            },

            confirmClone(id) {

                this.dialog = {
                    show: true,
                    headerText: this.$t('confirmacao'),
                    bodyText: 'Você irá CLONAR essa cotação, confirma sua decisão?',
                    methodConfirm: this.clonar,
                    params: id
                };

            },

            async clonar(id) {

                this.showLoading();

                this.$router.push({ name: "cotacaoForm", params: {id, clonar: true} });

                this.hideLoading();
            },

            copiar(id) {

                let item = this.listCotacao.filter(cot => cot.id == id)[0];

                if (item != null && item != undefined) {

                    let url = item.customerUrl;

                    if (url) {
                        navigator.clipboard.writeText(url);
                        this.showToast("success", this.$t('sucesso'), "Link copiado para a área de transferência!");
                    }
                }
            },
        },

        async created() {
            this.showLoading();

            this.elegivelParaCampanha = true;

            if (this.isUsuarioInterno == true) {
                this.headers = [
                    { text: "Núm Cotação", value: "numeroCotacao", sortable: true, size: "5%" },
                    { text: this.$t('criadoEm'), value: "dataCadastroFormatted", sortable: true },
                    { text: this.$t('criadoPor'), value: "nomeUsuarioCadastro", sortable: true },
                    { text: this.$t('marca'), value: "marcaDescricao", sortable: true },
                    { text: this.$t('tipoCliente'), value: "tipoClienteDescricao", sortable: true },
                    { text: this.$t('nome'), value: "nomeClienteDistribuidor", sortable: true },
                    { text: "Valor", value: "valorTotalBrutoFormatted", sortable: true, align: "right" },
                    { text: this.$t('status'), value: "statusDescricao", sortable: true },
                    { text: this.$t('acoes'), value: "action", sortable: false, align: "center" }
                ]
            }
            else {

                if (this.temDistribuidorPai == true) {

                    this.headers =  [
                        { text: "Núm Cotação", value: "numeroCotacao", sortable: true, size: "5%" },
                        { text: this.$t('criadoEm'), value: "dataCadastroFormatted", sortable: true },
                        { text: this.$t('criadoPor'), value: "nomeUsuarioCadastro", sortable: true },
                        { text: this.$t('nome'), value: "nomeClienteDistribuidor", sortable: true },
                        { text: this.$t('marca'), value: "marcaDescricao", sortable: true },
                        { text: "Valor", value: "valorTotalBrutoFormatted", sortable: true, align: "right" },
                        { text: this.$t('status'), value: "statusDescricao", sortable: true },
                        { text: this.$t('acoes'), value: "action", sortable: false, align: "center" }
                    ]
                }
                else {

                    this.headers =  [
                        { text: "Núm Cotação", value: "numeroCotacao", sortable: true, size: "5%" },
                        { text: this.$t('criadoEm'), value: "dataCadastroFormatted", sortable: true },
                        { text: this.$t('criadoPor'), value: "nomeUsuarioCadastro", sortable: true },
                        { text: this.$t('marca'), value: "marcaDescricao", sortable: true },
                        { text: "Valor", value: "valorTotalBrutoFormatted", sortable: true, align: "right" },
                        { text: this.$t('status'), value: "statusDescricao", sortable: true },
                        { text: this.$t('acoes'), value: "action", sortable: false, align: "center" }
                    ]

                }

                let resultSaldoTotal = await this.$store.dispatch("campanhaCreditoModule/ConsultarSaldoCampanhaTotvs", this.userLoggedGetters.distribuidorSelected.id);
				this.elegivelParaCampanha = resultSaldoTotal.result > 0;
            }

            await this.getRegisters();

            this.hideLoading();
        }
    })
</script>
